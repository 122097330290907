

























































import { Component, Vue } from 'vue-property-decorator'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'

@Component({})
export default class SignIn extends Vue {
	subdominio = ''
	erro = ''
	logo = require('@/assets/almode_256x181.png')

	async validaLogin(){
		try {
			window.location.href = `${location.protocol}//${this.subdominio}.${location.host}`
		} catch (error: any) {
			AlertModule.setError(error)
		}
	}

	get sufixoDoSite() {
		return location.host
	}
}
